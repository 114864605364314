<template>
  <el-form
    id="spEmailSettingsForm"
    ref="spEmailSettingsForm"
    :rules="rules"
    :model="emailSettings"
    label-position="top"
    size="large"
    hide-required-asterisk
    v-loading="isLoading || loading"
  >
    <el-row type="flex">
      <el-col :span="12">
        <el-form-item :label="__('Sender Email')" prop="sender_email">
          <el-input v-model="emailSettings.sender_email"></el-input>
        </el-form-item>

        <el-form-item :label="__('Sender Name')" prop="sender_name">
          <el-input v-model="emailSettings.sender_name"></el-input>
        </el-form-item>

        <el-form-item
          :label="__('Email Profile')"
          prop="use_default_email_profile"
        >
          <el-radio-group v-model="emailSettings.use_default_email_profile">
            <el-radio :label="true">{{ __("Use Default") }}</el-radio>
            <el-radio :label="false">
              {{ __("Use Custom Profile") }}

              <template
                v-if="emailSettings.use_default_email_profile === false"
              >
                <el-tag
                  v-if="emailSettings.custom_profile_is_verified === true"
                  type="success"
                  >{{ __("Verified") }}</el-tag
                >
                <el-tag
                  v-else-if="
                    emailSettings.custom_profile_is_verified === 'verifying'
                  "
                  >{{ __("Verifying") }}</el-tag
                >
                <el-tag v-else type="danger">{{ __("Unverified") }}</el-tag>
              </template>
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-card
          shadow="never"
          v-if="emailSettings.use_default_email_profile === false"
          style="margin-bottom: 20px"
        >
          <div>
            <el-row type="flex" :gutter="20">
              <el-col>
                <el-form-item
                  :label="__('SMTP Host')"
                  prop="custom_profile.smtp_host"
                  size="small"
                >
                  <el-input
                    v-model="emailSettings.custom_profile.smtp_host"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item
                  :label="__('SMTP Port')"
                  prop="custom_profile.smtp_port"
                  size="small"
                >
                  <el-input
                    v-model="emailSettings.custom_profile.smtp_port"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" :gutter="20">
              <el-col>
                <el-form-item
                  :label="__('SMTP Username')"
                  prop="custom_profile.smtp_username"
                  size="small"
                >
                  <el-input
                    v-model="emailSettings.custom_profile.smtp_username"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item
                  :label="__('SMTP Password')"
                  prop="custom_profile.smtp_password"
                  size="small"
                >
                  <el-input
                    type="password"
                    autocomplete="new-password"
                    v-model="emailSettings.custom_profile.smtp_password"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex" :gutter="20">
              <el-col>
                <el-button plain @click="verifyCustomEmailProfile">{{
                  __("Verify")
                }}</el-button>
              </el-col>
            </el-row>
          </div>
        </el-card>

        <el-form-item>
          <el-button
            @click="submitForm"
            class="submitBtn"
            :disabled="!canWrite()"
          >
            {{ __("Save") }}
          </el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";
export default {
  name: "SpEmailSettingsManageIndex",
  data() {
    let validateEmailProfile = (rule, value, callback) => {
      if (value === true) {
        callback();
      } else {
        if (this.emailSettings.custom_profile_is_verified !== true) {
          callback("Custom profile must be verified");
        } else {
          callback();
        }
      }
    };
    return {
      emailSettings: {
        sender_name: "",
        sender_email: "",
        use_default_email_profile: true,
        custom_profile_is_verified: false,
        custom_profile: {
          smtp_host: "",
          smtp_port: "",
          smtp_username: "",
          smtp_password: ""
        }
      },
      rules: {
        sender_email: [
          {
            type: "email",
            message: __("Please input a valid email address"),
            trigger: ["blur", "change"]
          }
        ],
        use_default_email_profile: [
          { validator: validateEmailProfile, trigger: ["change", "blur"] }
        ],
        custom_profile: {
          smtp_port: [
            {
              type: "number",
              message: __("Please input a valid SMTP port"),
              transform(value) {
                return _.toNumber(value);
              }
            }
          ]
        }
      },
      isLoading: false
    };
  },

  computed: {
    ...mapState("serviceproviders", {
      emailSettingsState: state => state.emailSettings,
      loading: state => state.loading
    })
  },

  methods: {
    ...mapActions("serviceproviders", {
      getEmailSetting: "getEmailSetting",
      storeEmailSetting: "storeEmailSetting",
      validateCustomEmailProfile: "validateCustomEmailProfile"
    }),

    verifyCustomEmailProfile() {
      this.$prompt(
        "Send a test email to validate the custom profile",
        "Email",
        {
          confirmButtonText: "Send",
          cancelButtonText: "Cancel",
          inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
          inputErrorMessage: __("Invalid Email"),
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = "Loading...";
              this.$set(
                this.emailSettings,
                "custom_profile_is_verified",
                "verifying"
              );
              this.validateCustomEmailProfile({
                send_to: instance.inputValue,
                custom_profile: this.emailSettings.custom_profile
              }).then(data => {
                instance.confirmButtonLoading = false;
                if (data && data.is_verifying == true) {
                  this.$message({
                    type: "info",
                    message:
                      "The email profile is being verified, you'll receive a result soon."
                  });
                } else {
                  this.$set(
                    this.emailSettings,
                    "custom_profile_is_verified",
                    false
                  );
                  this.$message({
                    type: "error",
                    message: __("Verification failed")
                  });
                }
                done();
              });
            } else {
              instance.confirmButtonLoading = false;
              done();
            }
          }
        }
      )
        .then(() => {})
        .catch(() => {
          console.log("catch error");
          this.$set(this.emailSettings, "custom_profile_is_verified", false);
          this.$message({
            type: "error",
            message: __("Verification cancelled")
          });
        });
    },

    submitForm() {
      this.isLoading = true;
      this.$refs.spEmailSettingsForm.validate(valid => {
        if (valid) {
          if (this.emailSettings.use_default_email_profile === true) {
            this.$set(this.emailSettings, "custom_profile", {});
          }
          this.storeEmailSetting(this.emailSettings)
            .then(() => {
              this.isLoading = false;
              this.$message({
                type: "success",
                message: __("Profile updated successfully.")
              });
            })
            .catch(err => {
              this.isLoading = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
      this.isLoading = false;
    },

    resetForm() {
      this.$set(this.emailSettings, "sender_name", "");
      this.$set(this.emailSettings, "sender_email", "");
      this.$set(this.emailSettings, "use_default_email_profile", true);
      this.$set(this.emailSettings, "custom_profile_is_verified", false);
      this.resetCustomProfile();
    },

    resetCustomProfile() {
      this.$set(this.emailSettings, "custom_profile", {});
      this.$set(this.emailSettings.custom_profile, "smtp_host", "");
      this.$set(this.emailSettings.custom_profile, "smtp_port", "");
      this.$set(this.emailSettings.custom_profile, "smtp_username", "");
      this.$set(this.emailSettings.custom_profile, "smtp_password", "");
    }
  },

  watch: {
    "emailSettings.custom_profile": {
      deep: true,
      handler: function(val) {
        if (
          this.emailSettings.custom_profile_is_verified == true &&
          !_.isEqual(val, this.emailSettingsState.custom_profile)
        ) {
          this.$set(this.emailSettings, "custom_profile_is_verified", false);
        }
      }
    }
  },

  async mounted() {
    this.isLoading = true;
    await this.getEmailSetting();
    if (!_.isEmpty(this.emailSettingsState)) {
      this.emailSettings = _.cloneDeep(this.emailSettingsState);
      if (_.isEmpty(this.emailSettings.custom_profile)) {
        this.resetCustomProfile();
      }
    } else {
      this.resetForm();
    }

    this.$echo
      .channel("sp_settings." + this.$auth.user().user_id)
      .listen("CustomEmailProfileVerificationEvent", eventData => {
        if (!_.isEmpty(eventData) && _.has(eventData, "is_verified")) {
          this.$set(
            this.emailSettings,
            "custom_profile_is_verified",
            eventData.is_verified
          );
          if (eventData.is_verified == true) {
            this.$refs.spEmailSettingsForm.validateField(
              "use_default_email_profile"
            );
            this.$message({
              type: "success",
              message: __("Custom profile is verified")
            });
          } else if (eventData.is_verified == false) {
            this.$message({
              type: "error",
              message: __("Verification failed")
            });
          }
        }
      });

    this.isLoading = false;
  },

  beforeDestroy: function() {
    this.$echo.leave("sp_settings." + this.$auth.user().user_id);
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";
@import "~@/styles/button.scss";
@import "~@/styles/form_common.scss";

.el-tag.el-tag--danger {
  background-color: #feedee !important;
  border-color: #fedadd !important;
  color: #f84856 !important;
}

.el-tag.el-tag--success {
  background-color: #eef8ed !important;
  border-color: #dcf1da !important;
  color: #51b848 !important;
}
</style>
